<template>
  <b-card>
    <div class="card-title">
      {{ ticket.ticket_no+ ' - ' +ticket.title }}
      <span
        :class="ticket.ticket_status === 'pending ml-3' ? 'text-warning ml-3'
          : ticket.ticket_status === 'answered ml-3' ? 'text-success ml-3'
            : ticket.ticket_status === 'closed ml-3' ? 'text-danger ml-3' : 'text-info ml-3'"
      >
        {{ ticket.ticket_status }}
      </span>
    </div>
    <b-col
      md="6"
      lg="5"
    >
      <p class="main-message">
        {{ ticket.body }}
        <small class="message-time text-primary">{{ ticket.created_at_formatted }}</small>
      </p>
    </b-col>
    <b-col
      md="6"
      lg="5"
    >
      <div
        v-for="(comment, id) in comments"
        :key="id"
        class="comments"
      >
        <small
          v-show="comment.merchant"
          class="message-time text-primary"
        >
          <b-avatar
            size="21"
            :variant="'primary'"
          >
            <feather-icon
              size="15"
              icon="UserIcon"
            />
          </b-avatar>         
          {{ comment.merchant ? comment.merchant.name +' - '+ comment.created_at_formatted : null }}
        </small>
        <p :class="comment.user ? 'support-message' : null ">
          <b-avatar
            size="21"
            :variant="'success'"
          >
            <feather-icon
              size="15"
              icon="HeadphonesIcon"
            />
          </b-avatar>          
          {{ comment.body }}
        </p>
      </div>
    </b-col>
    <b-form @submit.prevent="sendMessage">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Message"
            label-for="mc-message"
          >
            <b-form-textarea
              id="mc-message"
              v-model="details.body"
              placeholder="Message"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormTextarea, BForm, BButton, BCard, BSpinner, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
  components: {
    BRow,
    BAvatar,
    BCol,
    BFormGroup,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      comments: [],
      service: {},
      ticket: {},
      details: {},
      user: {},
      loading: false,
    }
  },
  created() {
    this.getComments()
    let user = localStorage.getItem('userData')
    this.user = JSON.parse(user)
    this.details.merchant_id = this.user.id
  },
  methods: {
    getComments() {
      this.$http.get(`${this.$url}/support/${router.currentRoute.params.id}`)
        .then(response => {
          console.log(response.data.data)
          if (response.data.status) {
            this.ticket = response.data.data
            this.comments = response.data.data.comments.sort()
            this.details.ticket_id = this.ticket.id
          }
        })
    },
    sendMessage() {
      this.loading = true
      this.$http.post(`${this.$url}/support/comment`, this.details)
        .then(response => {
          if (response.data.status) {
            window.location.reload()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
  .main-message {
    font-size: 16px;
    color: #000;
    font-weight: 500;

  }
  .message-time {
    font-size: 11px;
    text-transform: italic;
    font-weight: 700;
  }

  .comments {
    margin: 15px 0;
    .message-time {
      display: block;
    }
  }

  .support-comment {
    font-weight: 500;
    text-transform: italic;
  }
</style>
